/**
 * Created by Andrey Popov on 4/4/25.
 */

var ScrollTable = function (dataSource, options) {
    cleverapps.EventEmitter.call(this);

    this.minIconsCount = options.minIconsCount;
    this.addToStart = options.addToStart;

    this.dataSource = dataSource;

    this.rows = this.dataSource.getData();

    this.dataSource.on("add", this.addRow.bind(this), this);
    this.dataSource.on("remove", this.removeRow.bind(this), this);
    this.dataSource.on("reorder", this.reorder.bind(this), this);
};

ScrollTable.prototype = Object.create(cleverapps.EventEmitter.prototype);
ScrollTable.prototype.constructor = ScrollTable;

ScrollTable.prototype.getRows = function () {
    return this.rows;
};

ScrollTable.prototype.addRow = function (newRow) {
    var insertIndex = this.addToStart ? 0 : this.rows.length;

    for (var i = insertIndex; i < this.rows.length; i++) {
        this.trigger("move", i, i + 1);
    }

    this.rows.splice(insertIndex, 0, newRow);
    this.trigger("add", newRow, insertIndex);
};

ScrollTable.prototype.removeRow = function (rowId) {
    this.trigger("stop");

    var row = this.rows.find(function (r) {
        return r.id === rowId;
    });
    var removeIndex = this.rows.indexOf(row);

    this.rows.forEach(function (row, i) {
        if (i > removeIndex) {
            this.trigger("move", i, i - 1);
        }
    }.bind(this));
    this.rows.splice(removeIndex, 1);

    this.trigger("remove", removeIndex);
};

ScrollTable.prototype.reorder = function () {
    var updated = this.dataSource.getData();

    for (var i = 0; i < this.rows.length; i++) {
        var updatedRow = updated[i];

        if (this.rows[i].id !== updatedRow.id) {
            for (var j = i + 1; j < this.rows.length; j++) {
                if (this.rows[j].id === updatedRow.id) {
                    break;
                }
            }

            var temp = this.rows[i];
            this.rows[i] = this.rows[j];
            this.rows[j] = temp;

            this.trigger("swap", i, j);
        }
    }
};

ScrollTable.prototype.destructor = function () {
    runCleaners(this);

    this.dataSource.destructor();
};